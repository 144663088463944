import $ from 'jquery';

$(document).ready(function () {

  
    if (window.innerWidth < 500) {
        $("#SideMenuDiv").hide();
        $("#hideShowSidebarBtn").attr("hide", "true");

        $("#hideShowSidebarBtn").click(function () {
           
            if ($("#hideShowSidebarBtn").attr("hide") === "false") {
                $("#hideShowSidebarBtn").attr("hide", "true");
                $("#SideMenuDiv").slideUp();
            }
            else {
                $("#hideShowSidebarBtn").attr("hide", "false");
                $("#SideMenuDiv").slideDown();
            }
        })


    }
    else {


        $("#hideShowSidebarBtn").click(function () {
           
            if ($("#hideShowSidebarBtn").attr("hide") === "false") {
                $("#hideShowSidebarBtn").attr("hide", "true");
                $("#SideMenuDiv").css({ overflow: "hidden", padding: "0px" }).animate({ width: "0px" });
                $("#ContentDiv").animate({ width: "100%" });
            }
            else {
                $("#hideShowSidebarBtn").attr("hide", "false");
                $("#ContentDiv").animate({ width: "75%" });
                $("#SideMenuDiv").css({ overflow: "hidden", padding: "12px" }).animate({ width: "25%" });
            }
        })
    }
})