import React, { PureComponent } from 'react';
import $ from 'jquery'
import 'bootstrap/dist/js/bootstrap.bundle'
import swal from 'sweetalert';
import _ from "lodash";

export default class DailyiReward extends PureComponent {
    state = {
        dispplay: "block"
    }
    componentDidMount() {
        // $(".modal").modal("show");
        var html = "<div className='row'>";

        html += "<div className='col-sm-2 text-center'>";
        html += "<h5>Day1</h5>";
        html += "<img alt='icon' src='images/icon.png' width='75%'/><br>5 Coin";
        html += "</div>";

        html += "<div className='col-sm-2 text-center'>";
        html += "<h5>Day2</h5>";
        html += "<img alt='icon' src='images/icon.png' width='75%'/><br>5 Coin";
        html += "</div>";

        html += "<div className='col-sm-2 text-center'>";
        html += "<h5>Day3</h5>";
        html += "<img alt='icon' src='images/icon.png' width='75%'/><br>5 Coin";
        html += "</div>";

        html += "<div className='col-sm-2 text-center'>";
        html += "<h5>Day4</h5>";
        html += "<img alt='icon' src='images/icon.png' width='75%'/><br>5 Coin";
        html += "</div>";

        html += "<div className='col-sm-2 text-center'>";
        html += "<h5>Day5</h5>";
        html += "<img alt='icon' src='images/icon.png' width='75%'/><br>5 Coin";
        html += "</div>";

        html += "<div className='col-sm-2 text-center'>";
        html += "<h5>Day6</h5>";
        html += "<img alt='icon' src='images/icon.png' width='75%'/><br>5 Coin";
        html += "</div>";

        html += "<div className='col-sm-2 text-center'>";
        html += "<h5>Day6</h5>";
        html += "<img alt='icon' src='images/icon.png' width='75%'/><br>5 Coin";
        html += "</div>";

        html += "</div>";
        // swal({
        //     title: "Daily Reward",
        //     className: "mymodal",
        //     content: {
        //         element: 'div',
        //         attributes: {
        //             innerHTML: `${html}`,
        //         }
        //     }
        // })
    }

    render() {
        let day = 1;
        let modalConatiner = {
            "position": "fixed",
            "width": "100vw",
            "height": "100vh",
            "top": "0",
            "background": "rgba(0,0,0,0.2)",
            "z-index": "99900",
        }
        let modalBox = {
            "width": "80%",
            "height": "85%",
            "background": "rgba(255,255,255,1)",
            overflow: "auto",
            position: "relative",
            zIndex: "99902",

        }
        let closeBtn = {
            position: "fixed",
            right: "10px",
            top: "10px",
            fontSize: "38px",
            cursor: "pointer",
            zIndex: "99902"
        };
        return <div style={{ display: this.state.dispplay }}>
            <div style={modalConatiner} className="d-flex justify-content-center align-items-center">
                <div style={modalBox} className="shadow rounded-2 p-3">
                    <div style={closeBtn} onClick={() => {
                        this.setState({ dispplay: "none" });
                        // alert(this.state.dispplay)
                    }}>
                        <i className="fa fa-times"></i>
                    </div>
                    <h3 align="center" >Daily Reward</h3>
                    {window.innerWidth > 600 ?
                        <>

                            {(_.times(4, (i) => (
                                <div className="row">

                                    {(_.times(7, (j) => (
                                        <div className='col text-center py-3'>
                                            <h5 className='p-0 m-0'>Day{day++}</h5>
                                            <img alt='icon' src={day <= (parseInt(this.props.day) + 1) ? "images/icon.png" : "images/icon1.png"} width='60' /><br />5 Coin
                                        </div>
                                    )))}
                                </div>
                            )))}
                        </>
                        : (
                            <div className="row">
                                {
                                    (_.times(28, (j) => (
                                        <div className='col text-center py-3'>
                                            <h5 className='p-0 m-0'>Day{day++}</h5>
                                            <img alt='icon' src={day <= (parseInt(this.props.day) + 1) ? "images/icon.png" : "images/icon1.png"} width='60' /><br />5 Coin
                                        </div>
                                    )))
                                }</div>
                        )
                    }

                </div>
            </div>
        </div >;
    }
}
