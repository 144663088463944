import { EncryptStorage } from 'encrypt-storage';

class Session {
    constructor() {

        let options = {
            storageType: 'localStorage',
            stateManagementUse: true,
            // encAlgorithm: 'Rabbit',
            prefix: 'online',
        };
        this.encryptStorage = new EncryptStorage('sc5151515sdcsc', options)
    }

    setItem = (key, value) => {
        // localStorage.setItem("chck-" + key, value);
        // key = window.btoa(key);
        // this.encryptStorage.removeItem(key);
        this.encryptStorage.setItem(key, value);
    }
    getItem = (key1) => {
        // key1 = window.btoa(key1);
        let val = this.encryptStorage.getItem(key1)
        if (val !== undefined)
            return val;
        else
            return false;
    }
    removeItem = (key1) => {
        // key1 = window.btoa(key1);
        this.encryptStorage.removeItem(key1)

    }

}

export default new Session();