import React, { useState, useRef } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
// import "./Demo.css";

const defaultSrc =
    "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

export const ImageCropper = (props) => {
    console.log("props", props);
    const [image, setImage] = useState(null);
    const [cropData, setCropData] = useState(null);
    const cropperRef = useRef(null);
    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            props.setImg(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
        }
    };
    const cropready = (e) => {
        console.log("Sdcsdcsdcdscsd", e, cropperRef);

        cropperRef.current?.cropper.getCroppedCanvas({
            fillColor: '#fff',
            imageSmoothingEnabled: false,
            imageSmoothingQuality: 'low',
        });

    }

    return (
        <div>
            <div style={{ width: "100%" }}>
                <input type="file" onChange={onChange} />
                {image && !cropData &&
                    <>
                        <Cropper
                            style={{ height: 300, width: "100%" }}
                            initialAspectRatio={1}
                            src={image}
                            ref={cropperRef}
                            viewMode={1}
                            guides={true}
                            minCropBoxHeight={200}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            ready={cropready}
                            checkOrientation={true}
                        />
                        <button className="btn btn-sm btn-info " onClick={getCropData}>
                            {/* <i className="fa fa-crop"></i> */} Crop
                        </button>

                    </>
                }
                {cropData &&
                    <>
                        <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                        <button className="btn btn-sm btn-info " onClick={() => {
                            setCropData(null);
                        }}>
                            Re-Crop Image
                        </button>
                    </>
                }
            </div>
        </div >
    );
};

export default ImageCropper;
