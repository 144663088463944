import { post_api } from './Api'
import Session from '../Session1'
import { history } from '../Apphistory'
import { toast } from "react-toastify";
import swal from 'sweetalert';
import { handleChange } from './Api'
export const decrypt = (encrypted) => {
    return encrypted;
    encrypted = JSON.stringify(encrypted)

    var CryptoJS = require("crypto-js");
    var CryptoJSAesJson = {
        stringify: function (cipherParams) {
            var j = {
                ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)
            };
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
        },
        parse: function (jsonStr) {
            var j = JSON.parse(jsonStr);
            var cipherParams = CryptoJS.lib.CipherParams.create({
                ciphertext: CryptoJS.enc.Base64.parse(j.ct)
            });
            if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
            if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
            return cipherParams;
        }
    }

    // encrypted = '{"ct":"71AMUusggkgydBBdZYsIew==","iv":"1ddf59ad4655f6242666c1f31bf4ca7f","s":"e0c1591666f05370"}';
    let key = "1234";
    var decrypted = JSON.parse(JSON.parse(CryptoJS.AES.decrypt(encrypted, key, {
        format: CryptoJSAesJson
    }).toString(CryptoJS.enc.Utf8)));
    return decrypted;
}

export const CourseActions = (data = null) => async (dispatch) => {
    let response = await post_api("Course/getCourse", data);
    response.data = decrypt(response.data);
    console.log(response.data);
    if (response.data.removeToken == true) {
        Session.removeItem("token");
        Session.removeItem("stu_id");
        Session.removeItem("name");
        Session.removeItem("photo");
        Session.removeItem("Link");


        window.location.reload();
    }
    if (response.data.error == false)
        Session.setItem("course_data", response.data.data);
    dispatch({ type: "Fetch_home_page_data", payload: response.data });
};

export const setDeviceToken = async (FCMRoken = null) => {
    console.log(FCMRoken);
    let data = new FormData();
    data.append("deviceInfo", (JSON.stringify({ appVersion: navigator.appVersion || "", platform: navigator.platform || "", userAgent: navigator.userAgent || "", userAgentData: navigator.userAgentData || "", connection: navigator.connection.effectiveType || "" })));
    data.append("FCMRoken", FCMRoken);
    let response = await post_api("User/setDeviceToken", data);
    response.data = decrypt(response.data);
    console.log(response.data);
    if (response.data.error == false) {

        Session.setItem("deviceToken", response.data.deviceToken);
        // window.location.reload();
    }
};

export const Login = (data, url = "") => async (dispatch) => {
    let response = await post_api("User/Login", data);
    response.data = decrypt(response.data);
    console.log(response.data);
    if (response.data.error == false) {
        Session.setItem("token", response.data.token)
        Session.setItem("myHeader", JSON.stringify({ 'Content-Type': 'application/x-www-form-urlencoded', token: response.data.token }));
        Session.setItem("stu_id", response.data.stu_id)
        Session.setItem("name", response.data.name)
        Session.setItem("photo", response.data.photo)
        Session.setItem("Link", response.data.Link)
        Session.setItem("ReferLink", response.data.ReferLink)
        Session.setItem("Link1", response.data.Link1)
        Session.setItem("is_affliate", response.data.profile.is_affliate)
        Session.setItem("show_content", response.data.profile.show_content)
        dispatch({ type: "IsLogin", payload: true, profile: response.data.profile });
        if (url == "")
            history.push("/");
        else {
            if (url == "undefined" || url == undefined)
                history.push("/");
            else
                history.push("/" + url);
        }
    }
    else {
        swal("OOPS", response.data.message, "error");
        dispatch({ type: "IsLogin", payload: false });
    }
};
export const Logout = () => async (dispatch) => {

    Session.removeItem("token")
    Session.removeItem("myHeader");
    Session.removeItem("stu_id")
    Session.removeItem("name")
    Session.removeItem("photo")
    Session.removeItem("Link")
    dispatch({ type: "IsLogin", payload: false, profile: [] });
    history.push("/");

};

export const SocialLogin = (data) => async (dispatch) => {
    let response = await post_api("User/socialSignUp", data);
    response.data = decrypt(response.data);
    if (response.data.error == false) {
        Session.setItem("token", response.data.token)
        Session.setItem("stu_id", response.data.stu_id)
        Session.setItem("name", response.data.name)
        Session.setItem("photo", response.data.photo)
        Session.setItem("Link", response.data.Link)
        Session.setItem("Link1", response.data.Link1)
        dispatch({ type: "IsLogin", payload: true });
        window.location.href = ("/Dashboard");
    }
    else
        dispatch({ type: "IsLogin", payload: false });

};

export const getProfile = () => async (dispatch) => {
    let response = await post_api("User/getProfile");
    response.data = decrypt(response.data);
    console.log(response.data);
    if (response.data.removeToken == true) {
        Session.removeItem("token");
        Session.removeItem("stu_id");
        Session.removeItem("name");
        Session.removeItem("photo");
        Session.removeItem("Link");


        window.location.reload();
    }
    dispatch({ type: "Fetch_profile_data", payload: response.data });
};

export const getCourse = (data = null) => async (dispatch) => {
    let response = await post_api("Course/getStudentCourse", data);
    response.data = decrypt(response.data);
    console.log(response.data);
    if (response.data.removeToken == true) {
        Session.removeItem("token");
        Session.removeItem("stu_id");
        Session.removeItem("name");
        Session.removeItem("photo");
        Session.removeItem("Link");


        window.location.reload();
    }
    dispatch({ type: "Fetch_Student_Course", payload: response.data });
};

export const getFiles = (data = null) => async (dispatch) => {
    let response = await post_api("StudyMaterial/loadFileManger", data);
    response.data = decrypt(response.data);
    if (response.data.removeToken == true) {
        Session.removeItem("token");
        Session.removeItem("stu_id");
        Session.removeItem("name");
        Session.removeItem("photo");
        Session.removeItem("Link");


        window.location.reload();
    }
    dispatch({ type: "Load_FileManager", payload: response.data });
};

export const getTransaction = (data = null) => async (dispatch) => {
    let response = await post_api("Transaction/getTransaction", data);
    response.data = decrypt(response.data);
    console.log("getTransaction.response", response.data);
    if (response.data.removeToken == true) {
        Session.removeItem("token");
        Session.removeItem("stu_id");
        Session.removeItem("name");
        Session.removeItem("photo");
        Session.removeItem("Link");


        window.location.reload();
        window.location.reload();
    }
    dispatch({ type: "Fetch_Transaction", payload: response.data });
};

export const getFeedback = (data = null) => async (dispatch) => {
    let response = await post_api("User/getFeedback", data);
    response.data = decrypt(response.data);
    if (response.data.removeToken === true) {
        Session.removeItem("token");
        Session.removeItem("stu_id");
        Session.removeItem("name");
        Session.removeItem("photo");
        Session.removeItem("Link");



        window.location.reload();
    }
    dispatch({ type: "Fetch_Feedback", payload: response.data });
};

export const getQuery = (data = null) => async (dispatch) => {
    let response = await post_api("Query/getQuery", data);
    response.data = decrypt(response.data);
    dispatch({ type: "Fetch_Query", payload: response.data });
};

// $fields = ['type', 'description', 'status', 'complain_date'];
export const addQuery = (data = null) => async (dispatch) => {
    let response = await post_api("Query/addQuery", data);
    response.data = decrypt(response.data);
    const msg = response.data.message;
    // toast.success(msg, { position: "bottom-center" });
    swal(response.data.message, "", "success");
    dispatch({ type: "Add_Query", payload: response.data });
};
export const updateQuery = (data = null) => async (dispatch) => {
    let response = await post_api("Query/updateQuery", data);
    response.data = decrypt(response.data);
    const msg = response.data.message;
    // toast.success(msg, { position: "bottom-center" });
    swal(response.data.message, "", "success");
    // dispatch({ type: "Add_Query", payload: response.data });
};

export const updateProfile = (data = null) => async (dispatch) => {
    let response = await post_api("User/updateProfile", data);
    response.data = decrypt(response.data);
    const msg = response.data.message;
    // if (response.data.status === true)
    //     toast.success(msg, { position: "bottom-center" });
    // else
    //     toast.error(msg, { position: "bottom-center" });
    dispatch({ type: "profile_update", payload: response.data });
};

export const getFaq = () => async (dispatch) => {
    let response = await post_api("User/getFaq");
    response.data = decrypt(response.data);
    console.log(response);
    // const msg = response.data.message;
    // toast.success(msg, { position: "bottom-center" });
    dispatch({ type: "get_faq", payload: response.data });
};

export const getExam = () => async (dispatch) => {
    let response = await post_api("Exam/getExam");
    response.data = decrypt(response.data);
    dispatch({ type: "get_exam", payload: response.data });
};

export const getResult = () => async (dispatch) => {
    let response = await post_api("Exam/getResult");
    response.data = decrypt(response.data);
    dispatch({ type: "get_Result", payload: response.data });
};

export const getQuestion = (data) => async (dispatch) => {
    let response = await post_api("Exam/getQuestion", data);
    response.data = decrypt(response.data);
    console.log(response.data);
    if (response.data.error === true)
        swal(response.data.message)
    dispatch({ type: "get_question", payload: response.data });
};

export const getNotification = (data) => async (dispatch) => {
    let response = await post_api("User/getNotification", data);
    response.data = decrypt(response.data);
    dispatch({ type: "get_notification", payload: response.data });
};

export const getEWallet = (data) => async (dispatch) => {
    let response = await post_api("Transaction/getEWallet", data);
    response.data = decrypt(response.data);
    console.log(response.data);
    dispatch({ type: "get_ewallet", payload: response.data });
};

export const addFeedback = (data = null) => async (dispatch) => {
    let response = await post_api("User/addFeedback", data);
    response.data = decrypt(response.data);
    const msg = response.data.message;
    swal(response.data.message, "", "success")
    // toast.success(msg, { position: "bottom-center" });
};

export const submitAnswer = (data = null) => async (dispatch) => {
    let response = await post_api("Exam/submitAnswer", data);
    response.data = decrypt(response.data);
    // toast.success(msg, { position: "bottom-center" });
};

export const SubmitExam = (data = null) => async (dispatch) => {
    let response = await post_api("Exam/SubmitExam", data);
    response.data = decrypt(response.data);
    history.push("/Result")
};


export const validateUser = (data) => async (dispatch) => {

    let response = await post_api("User/validate", data);
    response.data = decrypt(response.data);
    // response.data = decrypt(response.data);

    dispatch({ type: "user_auth", payload: response.data });
}

export const resetAccount = (data) => async (dispatch) => {
    let response = await post_api("User/resetAccount", data);
    response.data = decrypt(response.data);
    // response.data = decrypt(response.data);
    dispatch({ type: "user_reset", payload: response.data });
}
export const syncActivity = (data) => async (dispatch) => {
    let response = await post_api("User/UpdateActivity", data);
    console.log("response",response);
    response.data = decrypt(response.data);
    console.log(response);
    // response.data = decrypt(response.data);
    // dispatch({ type: "user_reset", payload: response.data });
}


export const getMessage = (receiver_id, type = 'm') => async (dispatch) => {

    let response = await post_api("Message/getMessage/" + receiver_id + "/" + type);
    response.data = decrypt(response.data);
    if (response.data.removeToken == true) {
        Session.removeItem("token");
        Session.removeItem("stu_id");
        Session.removeItem("name");
        Session.removeItem("photo");
        Session.removeItem("Link");


        window.location.reload();

    }
    dispatch({ type: "get_message", payload: response.data });
};


export const getmebers = () => async (dispatch) => {

    let response = await post_api("Message/getmebers/");
    response.data = decrypt(response.data);
    console.log(response);
    dispatch({ type: "getmebers", payload: response.data });
};

export const getLeads = (data) => async (dispatch) => {
    let response = await post_api("User/getRandomLeads", data);
    response.data = decrypt(response.data);
    console.log(response.data);
    dispatch({ type: "get_leads", payload: response.data });
};
