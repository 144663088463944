import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Router } from "react-router-dom"
import { history } from './Apphistory'
import { Store } from './store'
import { Provider } from 'react-redux'
// import registerServiceWorker from './registerServiceWorker';

const app = ReactDOM.render(

  <Router history={history}>
    <Provider store={Store}>
      <App />
    </Provider>
  </Router>
  ,
  document.getElementById('root')
);
// registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
