import React, { PureComponent } from 'react'
import 'font-awesome/css/font-awesome.min.css'
import Session from './../Session1'
import { Link } from "react-router-dom";
import { FixHeader, profile_filled, hideContent } from "../Actions/CommonAction"
import { connect } from 'react-redux';
import { Url } from "./variables"
import { getProfile, Login } from "../Actions/CourseActions"
import { history } from '../Apphistory';
import swal from 'sweetalert';
import DailyiReward from "./DailyiReward"
import { DOMAIN_URL } from "../Actions/Api"


class navbar extends PureComponent {
    constructor(props) {
        super(props)
        console.log("nav constructor")

        this.state = {
            FixHeader: "",
            isLogin: false,
            showReward: false,
            showNavbar: false,
        }
    }

    componentDidMount() {
        // if (Session.getItem("token")) {
        //     this.props.getProfile();
        // }
        // if (Session.getItem("show_content") != 1) {
        //     if (window.location.hostname == "online.omitec.org") {
        //         window.location.href = 'https://omitec.co.in' + window.location.pathname;
        //     }
        // }
        if (window.location.search.includes("token")) {
            Session.setItem("mobile-app", true);

            var p = {};
            var vars = window.location.search.substring(1).split("&");
            console.log(vars)
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                p[pair[0]] = pair[1];
            }
            console.log("p", p)
            let data = new FormData();
            data.append('token', p.token);
            if (Session.getItem("Token") != p.token)
                this.props.Login(data, p.url);
        }

    }

    componentWillReceiveProps(props) {

        if (props.navClass)
            this.setState({ FixHeader: props.navClass })

        if (props.LoginData) {
            this.setState({ isLogin: props.LoginData })
        }

        if (props.profile_data) {
            console.log(props.profile_data)
            if (props.profile_data.ShowDailyReward) {
                this.setState({ showReward: true, day: props.profile_data.rewardDay });
            }
            if (props.profile_data.pincode == "" || props.profile_data.pincode == null) {
                var checkDate = new Date(props.profile_data.creation_datetime);
                checkDate.setDate(checkDate.getDate() + 2);
                if (checkDate < new Date()) {
                    this.props.profile_filled(true)
                    history.push("/Profile/Edit");
                    swal("Please Complete Your Profile", "", "info");
                }
                else
                    this.props.profile_filled(false)
            }
            else
                this.setState({ profileData: props.profile_data })
        }
    }

    render() {
        if (Session.getItem("mobile-app")) {
            return <></>
        }
        else
            return (
                <>
                    {this.state.showReward ? (<DailyiReward day={this.state.day} />) : ""}

                    < nav className={`navbar navbar-expand-lg navbar-dark navbar-custom fixed-top py-2 ${this.state.FixHeader}`}>
                        <div className='container'>
                            <Link className="navbar-brand logo-image animate__animated animate__slideInLeft" to="/">
                                {window.location.pathname !== '/' ?
                                    <>
                                        {/* <h3 className="ps-2">OMITEC E-Learning</h3> */}
                                        <img alt='icon' src={`${DOMAIN_URL}logo1.png`} className="w-100" />
                                    </>
                                    :
                                    <>
                                        {/* <h3 className="ps-2">OMITEC E-Learning</h3> */}
                                        <img alt='icon' src={`${DOMAIN_URL}logo1.png`} className="w-100" />
                                    </>
                                }
                            </Link>

                            {/* <!-- Mobile Menu Toggle Button --> */}
                            {
                                (this.props.LoginData) ?
                                    <>
                                        <button className="navbar-toggler ps-0" type="button">
                                            {/* <Link to='/Wallet'>
                                    <img src="images/icon.png" alt="" width="20" hspace="0" vspace="0" height="20" style={{ marginLeft: "0px", marginRight: "-5px" }} />
                                    <font size="3" style={{ marginRight: "2px" }}>{(this.state.profileData) ? this.state.profileData.coin : 0}</font>
                                </Link> */}
                                            <Link to="/Dashboard" className='btn btn-sm btn-warning rounded-pill' style={{ fontSize: '15px', fontWeight: 600 }}>
                                                Menu
                                            </Link>
                                            <Link to="/Profile">
                                                <img alt='icon' src={Session.getItem("photo").indexOf("http") >= 0 ? "/" + Session.getItem("photo") : Url(Session.getItem("photo"))} height="20" width="20" className="rounded-circle" style={{ marginRight: "2px", marginLeft: "5px" }} />
                                                <span className="navbar-toggler-awesome" style={{ fontSize: "25px" }}></span>
                                            </Link>
                                        </button>
                                    </>
                                    :
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation" onClick={() => {
                                        // alert();
                                        this.setState({ showNavbar: !this.state.showNavbar });
                                    }}>

                                        <span className="navbar-toggler-awesome fa fa-bars"></span>
                                        <span className="navbar-toggler-awesome fa fa-times"></span>
                                    </button>
                            }
                            {/* <!-- end of mobile menu toggle button --> */}

                            <div className={"collapse navbar-collapse  animate__animated animate__slideInRight" + (this.state.showNavbar == true ? " show" : "")} id="navbarsExampleDefault">
                                <ul className="navbar-nav ms-auto">
                                    {window.location.pathname == "/" ?
                                        <>
                                            <li className="nav-item">
                                                <Link className="nav-link page-scroll" to="/">Home <span className="sr-only">(current)</span></Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/view-cpp" className="nav-link page-scroll" href="">View CPP <span className="sr-only">(current)</span></Link>
                                            </li>
                                            {/* <li className="nav-item">
                                        <a className="nav-link page-scroll" href="#services">Courses</a>
                                    </li> */}
                                            {/* <li className="nav-item">
                                    <a className="nav-link page-scroll" href="#pricing">Packages</a>
                                </li> */}
                                            {/* <li className="nav-item">
                                        <a className="nav-link page-scroll" href="#request">Enquiry</a>
                                    </li> */}
                                        </>
                                        : <>
                                            <li className="nav-item">
                                                <Link to="/" className="nav-link page-scroll" href="">Home <span className="sr-only">(current)</span></Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/view-cpp" className="nav-link page-scroll" href="">View CPP <span className="sr-only">(current)</span></Link>
                                            </li>
                                        </>
                                    }
                                    {(this.props.LoginData) ? (
                                        <>
                                            <li className="nav-item dropdown d-none">

                                                <a className="nav-link dropdown-toggle page-scroll" href="#about" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                                    {/* <img src='<?= $stuData[' photo']; ?>' height=20 className="rounded-circle"> <?= get_session("stu_username"); ?> */}
                                                </a>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <a className="dropdown-item" href="Profile"><span className="item-text">Profile</span></a>
                                                    <div className="dropdown-items-divide-hr"></div>
                                                    <a className="dropdown-item" href="myCourse">
                                                        <span className="item-text">
                                                            My Courses
                                                        </span>
                                                    </a>
                                                    <div className="dropdown-items-divide-hr"></div>
                                                    <a className="dropdown-item" href="Video-Directory">
                                                        <span className="item-text">
                                                            Study Material
                                                        </span>
                                                    </a>
                                                    <div className="dropdown-items-divide-hr"></div>
                                                    <a className="dropdown-item" href="Transactions">
                                                        <span className="item-text">
                                                            Transactions
                                                        </span>
                                                    </a>
                                                    <div className="dropdown-items-divide-hr"></div>
                                                    <a className="dropdown-item" href="logout">
                                                        <span className="item-text">
                                                            Logout
                                                        </span>
                                                    </a>
                                                </div>
                                            </li>



                                            <li className="nav-item">
                                                <Link className="nav-link " to="/Profile">
                                                    <img alt='icon' src={Session.getItem("photo").indexOf("http") >= 0 ? Session.getItem("photo") : Url(Session.getItem("photo"))} height="20" width="20" className="rounded-circle" />
                                                    {/* <?= get_session("stu_username"); ?> */}
                                                    {Session.getItem("name")}
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link className="nav-link " to="/dashboard">
                                                    Menu
                                                    {/* <!-- <?= get_session("stu_username"); ?> --> */}
                                                </Link>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            {/* <li className="nav-item">
                                    <Link to="/" className="nav-link " >Home</Link>
                                </li> */}
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/login">Login</Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link className="nav-link " to="/Registration">Create Account</Link>
                                            </li>
                                        </>
                                    )}



                                    {/* <!-- <li className="nav-item">
                            <a className="nav-link page-scroll" href="#contact">Contact</a>
                        </li> --> */}
                                </ul>
                                {/* 
                    <!-- <span className="nav-item social-icons">
                        <span className="fa-stack">
                            <a href="#your-link">
                                <i className="fas fa-circle fa-stack-2x facebook"></i>
                                <i className="fab fa-facebook-f fa-stack-1x"></i>
                            </a>
                        </span>
                        <span className="fa-stack">
                            <a href="#your-link">
                                <i className="fas fa-circle fa-stack-2x twitter"></i>
                                <i className="fab fa-twitter fa-stack-1x"></i>
                            </a>
                        </span>
                    </span> --> */}
                            </div>
                        </div>
                    </nav >


                </>
            )
    }
}

const getState = (newState) => {
    return {
        navClass: newState.FixHeader.class,
        LoginData: newState.Login.data,
        profile_data: newState.getProfile.data,
        profile_filled: newState.profile_filled.data
    }
}
export default connect(getState, { FixHeader, getProfile, profile_filled, Login })(navbar)