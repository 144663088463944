// const server = "https://quiz.omitec.org/api/";
// const server = "/home2/mycen9yl/repositories/e-learn/CRM/";
// const server1 = "https://localhost/Quiz/";

import { CRM_URL } from "../Actions/Api";

const server = CRM_URL;
const server1 = "https://quiz.omitec.org/api/";
const Url = (path) => {
    if (path != "" && typeof path == "string") {
        if (path.startsWith("data")) {
            return path
        }
    }
    return server + path
}
const Url1 = (path) => {
    return server1 + path
}
let pdfKey = "";
if (window.location.host == "localhost:3000") {
    pdfKey = "09be2417358248c7a62fc20f7652353f"
}
else if (window.location.host == "omitec.co.in") {
    pdfKey = "e8857628d357460db45c287b58aafbab"
}
else {
    pdfKey = "2dbb91f08c88400badb2ed39e4fb08ca"
}
const themeDefault = 'dark'
const namesOfModes = ['dark', 'moonlight', 'eclipse', 'light']

export { Url, Url1, themeDefault, namesOfModes, pdfKey }