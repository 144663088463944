import Session from '../Session1'


export const Courses = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'Fetch_home_page_data':
            if (action.payload.error === false)
                return { data: action.payload.data, Package: action.payload.Package };
            else
                return { data: [] };
        default:
            return state;
    }
}

let loginState = { data: false };
if (Session.getItem("token")) {
    loginState = { data: true };
}

export const Login = (state = loginState, action) => {
    switch (action.type) {
        case 'IsLogin':
            if (action.payload === true)
                return { data: true, profile: action.profile };
            else
                return { data: false };
        default:
            return state;
    }
}

export const getProfile = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'Fetch_profile_data':
            if (action.payload.error === false)
                return { data: action.payload.data };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const getCourse = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'Fetch_Student_Course':
            if (action.payload.error === false)
                return { data: action.payload.data, Package: action.payload.Package };
            else
                return { data: [] };
        default:
            return state;
    }
}


export const Files = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'Load_FileManager':
            if (action.payload.error === false)
                return { data: action.payload.data };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const transactionData = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'Fetch_Transaction':
            if (action.payload.error === false)
                return { data: action.payload };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const getFeedback = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'Fetch_Feedback':
            if (action.payload.error === false)
                return { data: action.payload.data };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const getQuery = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'Fetch_Query':
            if (action.payload.error === false)
                return { data: action.payload.data, complainType: action.payload.complainType };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const addQuery = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'Add_Query':
            if (action.payload.error === false)
                return { data: action.payload.data };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const UpdateProfile = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'profile_update':
            return { data: action.payload };
        default:
            return state;
    }
}

export const Faq = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'get_faq':
            if (action.payload.error === false)
                return { data: action.payload.data };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const Exam = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'get_exam':
            if (action.payload.error === false)
                return { data: action.payload.data };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const getMessage = (state = { data: "" }, action) => {

    switch (action.type) {
        case 'get_message':
            if (action.payload.error === false)
                return { data: action.payload.data };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const getmebers = (state = { data: "" }, action) => {

    switch (action.type) {
        case 'getmebers':
            if (action.payload.error === false)
                return { data: action.payload.data };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const Question = (state = { data: "", ques_time: 0 }, action) => {
    switch (action.type) {
        case 'get_question':
            if (action.payload.error === false)
                return { data: action.payload.data, ques_time: action.payload.ques_time };
            else
                return { data: [], ques_time: 0 };
        default:
            return state;
    }
}

export const ExamSubmit = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'submit_exam':
            if (action.payload.error === false)
                return { data: action.payload.data };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const Result = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'get_Result':
            if (action.payload.error === false)
                return { data: action.payload.data };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const Notification = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'get_notification':
            if (action.payload.error === false)
                return { data: action.payload.data };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const wallet = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'get_ewallet':
            if (action.payload.error === false)
                return { data: action.payload.data };
            else
                return { data: [] };
        default:
            return state;
    }
}

export const signup = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'user_auth':
            return { data: action.payload };
        default:
            return state;
    }
}

export const resetAccount = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'user_reset':
            return { data: action.payload };
        default:
            return state;
    }
}

export const Leads = (state = { data: "" }, action) => {
    switch (action.type) {
        case 'get_leads':
            if (action.payload.status === true)
                return { data: action.payload.data };
            else
                return { data: [] };
        default:
            return state;
    }
}