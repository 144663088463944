
export const FixHeader = (state = { class: "" }, action) => {

    //console.log("commonReducer FixHeader state", state);
    //console.log("commonReducer FixHeader action", action);

    switch (action.type) {
        case 'FixHeader':
            return { class: action.class };
        default:
            return state;
    }
}

export const profile_filled = (state = { data: false }, action) => {

    //console.log("commonReducer profile_filled state", state);
    //console.log("commonReducer profile_filled action", action);

    switch (action.type) {
        case 'profile_filled':
            return { data: action.data };
        default:
            return state;
    }
}
export const hideContent = (state = { data: false }, action) => {

    //console.log("commonReducer hideContent state", state);
    //console.log("commonReducer hideContent action", action);
    switch (action.type) {
        case 'hideContent':
            return { data: action.data };
        default:
            return state;
    }
}
