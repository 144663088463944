import { combineReducers } from "redux";
import { Courses, Login, getProfile, getCourse, Files, transactionData, getFeedback, getQuery, addQuery, Faq, Exam, Question, ExamSubmit, Result, Notification, wallet, signup, resetAccount, UpdateProfile, getMessage, getmebers,Leads } from './CourseReducer';
import { FixHeader, profile_filled, hideContent } from './CommonReducer';

export const rootReducers = combineReducers({
    Courses,
    transactionData,
    getFeedback,
    getQuery,
    addQuery,
    Login,
    getProfile,
    FixHeader,
    Files,
    profile_filled,
    getCourse,
    Faq,
    Exam,
    Question,
    Result,
    ExamSubmit,
    Notification,
    wallet,
    signup,
    resetAccount,
    UpdateProfile,
    getMessage,
    getmebers,
    Leads,
    hideContent
});


